<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel({
    type: String,
    required: true,
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });


</script>

<template>
    <input
        class="text-sm border rounded-lg border-divider py-4 px-3.5 placeholder:text-disabled focus:placeholder:text-transparent  focus:border-main focus:ring-main text-primary"
        v-model="model"
        ref="input"
    />
</template>
